// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-2018-2019-index-js": () => import("./../../../src/pages/2018-2019/index.js" /* webpackChunkName: "component---src-pages-2018-2019-index-js" */),
  "component---src-pages-2019-2020-index-js": () => import("./../../../src/pages/2019-2020/index.js" /* webpackChunkName: "component---src-pages-2019-2020-index-js" */),
  "component---src-pages-2020-2021-index-js": () => import("./../../../src/pages/2020-2021/index.js" /* webpackChunkName: "component---src-pages-2020-2021-index-js" */),
  "component---src-pages-2021-2022-index-js": () => import("./../../../src/pages/2021-2022/index.js" /* webpackChunkName: "component---src-pages-2021-2022-index-js" */),
  "component---src-pages-2022-2023-index-js": () => import("./../../../src/pages/2022-2023/index.js" /* webpackChunkName: "component---src-pages-2022-2023-index-js" */),
  "component---src-pages-2023-2024-index-js": () => import("./../../../src/pages/2023-2024/index.js" /* webpackChunkName: "component---src-pages-2023-2024-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-examples-js": () => import("./../../../src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../../../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-legacy-index-js": () => import("./../../../src/pages/legacy/index.js" /* webpackChunkName: "component---src-pages-legacy-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-post-video-js": () => import("./../../../src/templates/blog-post-video.js" /* webpackChunkName: "component---src-templates-blog-post-video-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

